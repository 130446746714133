import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Artificial intelligence is gaining momentum. Be it self-driving cars, image generation, or chatbots – AI is more and more
a part of our everyday life. The conversational agent `}<a parentName="p" {...{
        "href": "https://openai.com/blog/chatgpt/"
      }}>{`ChatGPT`}</a>{` shows just how advanced and
human-like AI can be.`}</p>
    <p>{`A lot of people have already shared their `}<a parentName="p" {...{
        "href": "https://mastodon.social/tags/ChatGPT"
      }}>{`conversations with the AI`}</a>{`. It impresses with
thoughtful and well composed answers. But how would the chatbot react to rather pointed questions and biased opinions? To answer
this question, I just had a casual conversation with ChatGPT about some widespread accessibility myths.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHbE1ZrdEF//8QAGRAAAwADAAAAAAAAAAAAAAAAAAECERIx/9oACAEBAAEFAlk2UpXLUiJ5/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bp//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAXEAEAAwAAAAAAAAAAAAAAAAAAICEx/9oACAEBAAY/Alth/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQETGB/9oACAEBAAE/IaFg1TvwsgxI/9oADAMBAAIAAwAAABA3/wD/xAAXEQEAAwAAAAAAAAAAAAAAAAAAASFR/9oACAEDAQE/EI0p/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQBh/9oACAECAQE/EMSt/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIXEQMVH/2gAIAQEAAT8QdgCbleQWsLhAmn0eDuYHcRWgn//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A human hand with an outstretched index finger approaches a robot hand.",
          "title": "A human hand with an outstretched index finger approaches a robot hand.",
          "src": "/static/fcc689f3c7407cd695a248bde7290b31/e5166/pexels-tara-winstead-robot-and-human.jpg",
          "srcSet": ["/static/fcc689f3c7407cd695a248bde7290b31/f93b5/pexels-tara-winstead-robot-and-human.jpg 300w", "/static/fcc689f3c7407cd695a248bde7290b31/b4294/pexels-tara-winstead-robot-and-human.jpg 600w", "/static/fcc689f3c7407cd695a248bde7290b31/e5166/pexels-tara-winstead-robot-and-human.jpg 1200w", "/static/fcc689f3c7407cd695a248bde7290b31/b17f8/pexels-tara-winstead-robot-and-human.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Tara Winstead / pexels.com`}</em></p>
    <p>{`I split the conversation into several sections, starting with common questions, followed by four widespread myths about
accessibility. At the end of the article, I'll share my thoughts about ChatGPT with you.`}</p>
    <h2>{`My conversation with ChatGPT`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> Hi ChatGPT! Everyone's talking about web accessibility. Can you explain the concept in simple terms?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Sure! Web accessibility refers to the practice of making websites and web applications usable by people with disabilities. This includes people with visual, auditory, motor, and cognitive impairments, among others. The goal of web accessibility is to allow all users to access and use the content and features on a website, regardless of any disabilities they may have. This can be achieved by following a set of web accessibility guidelines and standards, such as the Web Content Accessibility Guidelines (WCAG) developed by the World Wide Web Consortium (W3C). These guidelines provide a set of specific criteria that websites and web applications should meet in order to be considered accessible. By following these guidelines, website developers and designers can ensure that their sites are usable by a wide range of users.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> I'm afraid that it will make web development more expensive and overly complicated. What is your opinion?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Incorporating web accessibility into the design and development of a website or web application does not have to be expensive or overly complicated. In fact, following web accessibility guidelines can often improve the overall user experience for all users, not just those with disabilities. For example, using clear and descriptive headings and labels on forms can make it easier for everyone to navigate and use the site, not just people with visual impairments. Additionally, using proper formatting and structure can make it easier for screen-reading software to interpret and read the content of a page, benefiting users who are blind or have low vision.`}</p>
    <h2>{`Myth #1: Accessibility benefits only a small group of people`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> Ok, sound's great. What are the major benefits of an accessible website design?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` There are several benefits to having an accessible website design. For users with disabilities, the most obvious benefit is the ability to access and use the content and features of the site. This can improve their overall experience of using the internet and allow them to participate in online activities that they may have previously been unable to do.`}</p>
    <p>{`In addition to benefiting users with disabilities, accessible website design can also provide benefits for businesses and organizations. For example, an accessible website can improve the search engine optimization (SEO) of the site, making it more visible to search engines and potentially increasing traffic to the site. An accessible website can also increase the potential audience for the site, since it can be used by a wider range of users. This can be especially beneficial for businesses and organizations that serve a diverse population or that are required by law to provide accessible services.`}</p>
    <p>{`Finally, incorporating web accessibility into the design of a website can also improve the overall user experience for all users, regardless of any disabilities they may have. This can lead to increased customer satisfaction and loyalty, as well as potential cost savings by reducing the need for customer support.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> I'm not sure. Doesn't accessibility only benefit a small group of people?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` While it is true that web accessibility primarily benefits users with disabilities, the potential audience for accessible websites is actually quite large. According to the World Health Organization, about 15% of the global population lives with some form of disability. This means that, in terms of sheer numbers, the potential audience for accessible websites is significant. Additionally, web accessibility can benefit a wide range of users with different types of disabilities, including visual, auditory, motor, and cognitive impairments, as well as users who may not have a permanent disability but who may have temporary impairments (such as a broken arm) or situational limitations (such as using a website on a mobile device while on the go). Overall, the potential audience for accessible websites is much larger than many people realize.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> You said that the potential audience for accessible websites was significant. But do people with disabilities really use the internet that much?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Yes, people with disabilities use the internet at similar rates to those without disabilities. A study by the Pew Research Center found that, in the United States, about 88% of adults with disabilities use the internet, compared to 91% of adults without disabilities. This indicates that people with disabilities are just as likely to use the internet as those without disabilities, and highlights the importance of ensuring that websites are accessible to all users.`}</p>
    <p>{`Additionally, the use of the internet by people with disabilities is likely to increase in the coming years, as advances in assistive technologies and the proliferation of mobile devices make it easier for people with disabilities to access the internet. This means that the potential audience for accessible websites will continue to grow, making web accessibility an increasingly important consideration for businesses and organizations.`}</p>
    <h2>{`Myth #2: Accessibility is easy and should be the last step`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> Alright, let's say I intend to create accessible websites. How hard can it be? I think accessibility can wait until the end of the development process after all of the other work is done.</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` While it is certainly possible to make a website accessible after it has been developed, it is generally much easier and more effective to incorporate web accessibility into the design and development process from the beginning. This is because accessibility is not just a matter of adding a few accessibility features at the end of the development process, but rather it involves considering accessibility at every stage of the design and development process.`}</p>
    <p>{`For example, when designing the layout and content of a website, it is important to consider how the site will be used by people with disabilities and to ensure that the design is usable by a wide range of users. This may involve using clear and descriptive headings and labels, providing alternative text for images, and using proper formatting and structure to make the content easily readable by screen-reading software.`}</p>
    <p>{`During the development process, it is also important to test the website to ensure that it meets web accessibility standards and guidelines. This can be done using a combination of automated testing tools and manual testing by individuals with disabilities or other specialized expertise. By incorporating accessibility into the design and development process from the beginning, it is much more likely that the final website will be accessible to all users.`}</p>
    <p>{`Overall, while it is possible to make a website accessible after it has been developed, it is much easier and more effective to consider web accessibility from the beginning. This can help to ensure that the website is usable by a wide range of users and meets the relevant accessibility standards and guidelines.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> But accessibility is so restrictive. Wouldn't this hamper the creativity of web designers and developers?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` Web accessibility does not have to restrict the creativity of web designers and developers. In fact, incorporating accessibility into the design and development process can often lead to more creative and effective solutions. This is because accessibility is not just about adding a few accessibility features to a website, but rather it involves considering the needs of all users, including those with disabilities, and finding ways to make the website usable and enjoyable for everyone.`}</p>
    <p>{`For example, when designing the layout and content of a website, web designers and developers can consider how the site will be used by people with disabilities and look for creative ways to make the site accessible and usable. This may involve using innovative design techniques, such as responsive design, to make the site accessible on a wide range of devices, or incorporating multimedia elements, such as videos or audio recordings, to make the content more accessible to users with visual or auditory impairments.`}</p>
    <p>{`Additionally, by incorporating accessibility into the design and development process, web designers and developers can often improve the overall user experience of the site for all users. This can lead to more creative and effective solutions that are not only accessible, but also engaging and enjoyable for all users.`}</p>
    <p>{`Overall, web accessibility does not have to restrict the creativity of web designers and developers. In fact, by considering the needs of all users and incorporating accessibility into the design and development process, web designers and developers can create websites that are not only accessible, but also innovative and effective.`}</p>
    <h2>{`Myth #3: Accessibility is too hard and expensive`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> Still, there are so many different disabilities and assistive technologies to consider. Training employees and integrating accessibility into the whole development process is just too expensive. What do you think?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` While it is true that training employees and incorporating accessibility into the design and development process can require some initial investment, the long-term benefits of web accessibility can outweigh the costs. For businesses and organizations, having an accessible website can increase the potential audience for the site, improve the search engine optimization (SEO) of the site, and lead to increased customer satisfaction and loyalty. This can result in significant financial benefits, such as increased sales or donations, and can help to offset the initial costs of implementing web accessibility.`}</p>
    <p>{`Additionally, there are many resources available to help businesses and organizations implement web accessibility, including training materials, guidelines and standards, and automated testing tools. These resources can help to make the process of implementing web accessibility more efficient and cost-effective.`}</p>
    <p>{`Overall, while implementing web accessibility can require some initial investment, the long-term benefits of an accessible website can outweigh the costs. By considering the needs of all users and incorporating accessibility into the design and development process, businesses and organizations can create websites that are usable by a wide range of users and that can provide significant financial benefits.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> Wouldn't it be much cheaper to simply use an accessibility overlay?</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` An accessibility overlay is a tool that can be added to an existing website to make it more accessible to users with disabilities. While an accessibility overlay can provide some benefits, it is generally not as effective or efficient as incorporating accessibility into the design and development process from the beginning.`}</p>
    <p>{`One major disadvantage of using an accessibility overlay is that it only addresses the accessibility of the user interface (UI) of the website, not the underlying content or structure. This means that, while the UI may be more accessible with an overlay, the content of the site may still be difficult or impossible for users with disabilities to access. For example, an accessibility overlay may add alternative text to images, but if the images are not properly labeled or organized in the first place, they may still be difficult for users with visual impairments to understand.`}</p>
    <p>{`Additionally, using an accessibility overlay can be less efficient and more expensive than incorporating accessibility into the design and development process. This is because an accessibility overlay must be added to the site after it has been developed, which can require additional time and resources. In contrast, incorporating accessibility into the design and development process from the beginning can be more efficient and cost-effective, since it involves considering accessibility at every stage of the process.`}</p>
    <p>{`Overall, while an accessibility overlay can provide some benefits, it is generally not as effective or efficient as incorporating accessibility into the design and development process from the beginning. By considering the needs of all users and incorporating accessibility into the design and development process, businesses and organizations can create websites that are truly accessible to all users.`}</p>
    <h2>{`Myth #4: Accessible websites are ugly`}</h2>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> Maybe you're right and we should design for accessibility from the start. It's just so hard to create accessible websites that look good. Most accessible websites are ugly and have poor visual design.</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` While it is true that some accessible websites may not have the same visual appeal as other websites, this does not have to be the case. Accessibility and visual design are not mutually exclusive, and it is possible to create websites that are both accessible and aesthetically pleasing.`}</p>
    <p>{`One way to achieve this is by following web accessibility guidelines and standards, such as the Web Content Accessibility Guidelines (WCAG) developed by the World Wide Web Consortium (W3C). These guidelines provide specific criteria that websites should meet in order to be considered accessible, while also allowing for flexibility in the design and layout of the site. This means that web designers and developers can use their creativity and expertise to create visually appealing websites that are also accessible to users with disabilities.`}</p>
    <p>{`Additionally, by considering the needs of all users, including those with disabilities, web designers and developers can create websites that are not only accessible and visually appealing, but also user-friendly and engaging for a wide range of users. This can lead to more effective and successful websites that are appreciated by all users, regardless of any disabilities they may have.`}</p>
    <p>{`Overall, while some accessible websites may not have the same visual appeal as other websites, it is possible to create websites that are both accessible and aesthetically pleasing. By following web accessibility guidelines and standards and considering the needs of all users, web designers and developers can create websites that are accessible, visually appealing, and user-friendly.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> What about underlining links? The WCAG success criterion 1.4.1 basically forces you to underline links, although it hurts the aesthetic appeal of a website.</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` The Web Content Accessibility Guidelines (WCAG) success criterion 1.4.1 states that “information and relationships conveyed through presentation can be programmatically determined or are available in text.” This means that any information or relationships that are conveyed visually through the presentation of a website, such as the location of a link, should also be available in text so that they can be understood by users with visual impairments who may not be able to see the visual presentation of the site.`}</p>
    <p>{`In the case of links, the WCAG success criterion 1.4.1 does not necessarily require that links be underlined, but it does require that the location of the link be clearly indicated in text. This can be achieved in a number of ways, such as by using a different color for links, by using a unique style or font for links, or by underlining links. The specific method used to indicate the location of a link is up to the web designer or developer, as long as it meets the requirements of the WCAG success criterion 1.4.1.`}</p>
    <p>{`Ultimately, the goal of the WCAG success criterion 1.4.1 is to ensure that the information and relationships conveyed through the presentation of a website are available in text so that they can be understood by users with visual impairments. While underlining links is one way to meet this criterion, there are other options available that can also provide good visual design while ensuring accessibility.`}</p>
    <BoldText isParagraph={true} mdxType="BoldText"><em>Me:</em> You mixed up the criteria 1.3.1 and 1.4.1. The success criterion 1.4.1 actually states that “color is not used as the only visual means of conveying information, indicating an action, prompting a response, or distinguishing a visual element.”</BoldText>
    <p><em parentName="p">{`ChatGPT:`}</em>{` I apologize if I mixed up the WCAG success criteria. The success criterion 1.4.1 that I mentioned does indeed state that “color is not used as the only visual means of conveying information, indicating an action, prompting a response, or distinguishing a visual element.” This means that websites should not use color as the only way to convey information or indicate an action, since this can make the content difficult or impossible to understand for users with visual impairments who may be unable to see the...`}</p>
    <hr></hr>
    <h2>{`Conclusion`}</h2>
    <p>{`In the end, ChatGPT suddenly broke off mid-sentence and became unresponsive. Maybe I overheated its artificial synapses, who knows.`}</p>
    <p>{`Overall, I'm impressed by the detailed and mostly accurate responses of ChatGPT. Talking to the AI, it actually feels
like you're having a conversation with another human being. It's kind of eerie.`}</p>
    <p>{`Of course, the AI isn't perfect. After some time, the answers seem a little repetitive. Also, when I asked a specific question
about the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/Understanding/use-of-color.html"
      }}>{`WCAG success criterion 1.4.1`}</a>{`, ChatGPT responded with
erroneous information. I suppose that with more training, those errors will happen less and less often.`}</p>
    <p>{`Have you already had a conversation with ChatGPT? What was your experience like?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      